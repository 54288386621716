type TypedOperators = {
    string: string[];
    boolean: string[];
    bit: string[];
    number: string[];
    date: string[];
    datetime: string[];
    uniqueidentifier: string[];
};


type OperatorTitles = { [key: string]: string };


type Operators = { [key: string]: string };


type ValueTypes = { [key: string]: string };


type OperatorDefaults = {
    [key in keyof TypedOperators]: string;
};

export type {
    OperatorDefaults,
    OperatorTitles,
    TypedOperators,
    ValueTypes,
    Operators
}